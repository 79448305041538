import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaDribbble, FaInstagram } from "react-icons/fa";


const SplashFooter = () => {
    return (
        <footer className="footer-area splash-footer">
            <div className="container">
                <div className="footer-bottom">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                        <div className="footer-copyright">
                            <span className="copyright-text">© {new Date().getFullYear()} - Tous droits réservés par <a href="https://leonie-dessert.fr/">Léonie Dessert</a></span>
                        </div>
                        </div>
                        <div className="col-lg-2">
                        <ul className="footer-social list-unstyled">
                            <li><a href="https://www.facebook.com/LeonieDessert/" target="_blank"><FaFacebookF /></a></li>
                            <li><a href="https://www.instagram.com/leonie.dessert/" target="_blank"><FaInstagram /></a></li>
                        </ul>
                        </div>
                        <div className="col-lg-5">
                        <div className="footer-bottom-link">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/mentions-legales"}>Mention Légales</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Politique de confidentialité</Link></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default SplashFooter;