import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import useAxiosPost from '../../hook/usePost';
import Alert from 'react-bootstrap/Alert';
import ReCAPTCHA from 'react-google-recaptcha';

const Result = ({ message, variant }) => {
    return (
        <Alert variant={variant} className="message-alert">
            {message}
        </Alert>
    );
}

const FormTwo = () => {
    const form = useRef();
    const { postData, loading, error } = useAxiosPost();

    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [result, showResult] = useState(null);

    const onCaptchaChange = (value) => {
        setCaptchaToken(value);
        setCaptchaVerified(!!value);
    };

    const sendEmail = async (e) => {
        e.preventDefault();

        // Vérification du CAPTCHA avant d'envoyer le formulaire
        if (!captchaVerified) {
            showResult({ message: "Veuillez compléter le captcha avant d'envoyer le message.", variant: "danger" });
            return;
        }

        const formData = new FormData(form.current);
        const data = {
            name: formData.get('name'),
            surname: formData.get('surname'),
            email: formData.get('email'),
            phoneNumber: formData.get('phoneNumber'),
            message: formData.get('message'),
            captchaToken: formData.get('g-recaptcha-response')
        };
        try {
            const response = await postData(process.env.REACT_APP_API_URL + '/send-email', data);
            if (response && response.status === 200) {
                showResult({ message: "Votre message a bien été envoyé.", variant: "success" });
                form.current.reset();
                setCaptchaVerified(false);
            }
        } catch (error) {
            console.error(error);
            showResult({ message: "Une erreur est survenue lors de l'envoi du message.", variant: "danger" });
        }

        setTimeout(() => {
            showResult(null);
        }, 10000);
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <label>Nom</label>
                <input type="text" className="form-control" name="name" required />
            </div>
            <div className="form-group">
                <label>Prénom</label>
                <input type="text" className="form-control" name="surname" required />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" name="email" required />
            </div>
            <div className="form-group">
                <label>Téléphone</label>
                <input type="tel" className="form-control" name="phoneNumber" required />
            </div>
            <div className="form-group mb--40">
                <label>Message</label>
                <textarea className="form-control" name="message" rows="4"></textarea>
            </div>
            <div className="form-group">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    onChange={onCaptchaChange}
                />
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Envoyer</button>
            </div>
            <div className="form-group">
                {result ? <Result message={result.message} variant={result.variant} /> : null}
            </div>
        </form>
    );
}

export default FormTwo;
