import React from 'react';
import ProjectPropOne from './itemProp/ProjectPropOne';
import useFetch from '../../hook/useFetch';


const ProjectOne = ({parentClass, colSize, itemShow, columnGap, categoryId}) => {
	const { data: cakes } = useFetch(process.env.REACT_APP_API_URL + `/cakes?category=${categoryId}`);

    return (
		<>
			<div className={`section section-padding-2 ${parentClass ? parentClass : ""}`}>
                <div className="container">
					<div className={`row ${columnGap ? columnGap : "row-35"}`}>
						{cakes.map((data) => (
							<div className={colSize ? colSize : "col-md-6"} key={data.id}>
								<ProjectPropOne projectStyle="" data={data}/>
							</div>
						))}
					</div>

                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>
            </div>
		</>
    )
}

export default ProjectOne;