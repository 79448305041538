import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import SplashFooter from '../common/footer/SplashFooter';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';



const Contact = () => {
    return (
        <>
            <SEO title="Contactez Léonie Dessert" />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Contact"
                page="Contact"
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--80">
                                <h4 className="title">Téléphone</h4>
                                <h4 className="phone-number"><a href="tel:0673564521">06 73 56 45 21</a></h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">Adresse</h4>
                                <p>
                                    E.I. LEONIE DESSERT <br />
                                    1 rue de Kernevez <br />
                                    22740 PLEUMEUR GAUTIER
                                </p>
                                <h4 className="title">Horaires</h4>
                                <p>
                                    Lundi : Fermeture
                                    <br/>
                                    Mardi : 9:00 - 18:00
                                    <br/>
                                    Mercredi : 9:00 - 18:00
                                    <br/>
                                    Jeudi : 9:00 - 18:00
                                    <br/>
                                    Vendredi : 9:00 - 18:00
                                    <br/>
                                    Samedi : 9:00 - 18:00
                                    <br/>
                                    Dimanche : 8:30 - 12:30
                                </p>
                                {/* <h4 className="phone-number"><a href="mailto:leonie-dessert@gmail.com">leonie-dessert@gmail.com</a></h4> */}
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">Formulaire de contact</h3>
                                <FormTwo />
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>
            <SplashFooter parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;