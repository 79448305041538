import React from 'react';
import {Link, useParams} from 'react-router-dom';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectData from "../data/project/ProjectData.json";
import Accordion from 'react-bootstrap/Accordion';
import { FaListUl, FaTag, FaHandHoldingMedical } from 'react-icons/fa';
import SEO from '../common/SEO';
import useFetch from '../hook/useFetch';
import SplashFooter from '../common/footer/SplashFooter';
import CakePricing from '../component/pricing/CakePricing'

const ProjectDetails = () => {
    const { slug } = useParams();
    const { data: cake } = useFetch(process.env.REACT_APP_API_URL + `/cakes?slug=${slug}`);

    return (
        <>
        <SEO title="Project Details"/>
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerTwo 
                title={cake.name}
                paragraph ={cake.description}
                mainThumb={cake.image}
            />
            <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">
                                    {/* <span>{cake.category}</span>  */}
                                </span>
                                <h3 className="title">{cake.name}</h3>
                                {cake.descriptionLong}
                            </div>
                            <Link to="/contact" className="axil-btn btn-fill-primary">Contactez Léonie Dessert</Link>
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    <h3 className="title">Informations</h3>
                                    <p>Retrouvez toutes les informations au sujet du {cake.name}</p>
                                </div>
                                <Accordion>
                                <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaTag /> Tarif</Accordion.Header>
                                        <Accordion.Body>
                                            <CakePricing cake={cake}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaListUl /> Ingrédients</Accordion.Header>
                                        <Accordion.Body>
                                            {cake.ingredients && cake.ingredients.map(ingredient => ingredient.name).join(', ')}
                                            {cake.ingredients && cake.ingredients.some(ingredient => ingredient.isAlcool) && (
                                                <div style={{ color: 'red', marginTop: '10px', fontStyle: 'italic' }}>
                                                    Ce produit contient de l'alcool
                                                </div>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><FaHandHoldingMedical /> Allérgénes</Accordion.Header>
                                        <Accordion.Body>
                                            {cake.allergens && cake.allergens.map(allergen => allergen.name).join(', ')}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <CtaLayoutOne /> */}
        <SplashFooter parentClass="" />
        </main>
        </>
    )
}

export default ProjectDetails;