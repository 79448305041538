import React from 'react';

const CakePricing = (cake) => {
    const cakeObject = cake.cake;

    const formatPrice = (price) => {
        if (price == null) {
            return;
        }

        return `${price.toFixed(2).replace('.', ',')}€`;
    };

    const displayPrice = (cake) => {
        const pricePerSlice = cake.pricePerSlice;
        const priceForQuantities = cake.priceForQuantities;

        if (pricePerSlice) {
            return (
                <>
                    {formatPrice(pricePerSlice)} / part
                    <br />
                    A partir de 6 parts 
                </>
            );
        }

        if (!priceForQuantities) {
            return;
        }

        return (
            <>
                <ul>
                    {priceForQuantities.map((price, index) => (
                        <li key={index}>{formatPrice(price.price)} pour {price.quantity} parts</li>
                    ))}
                </ul>
            </>
        )

    }
    
    return (
        <>
            {displayPrice(cakeObject)}
        </>
    )
}

export default CakePricing;