import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SplashFooter from '../common/footer/SplashFooter';
import SEO from '../common/SEO';


const LegalNotices = () => {
    return (
        <>
            <SEO title="Mentions Légales" />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Mentions Légales"
                page="Mentions Légales"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <h4>1. Éditeur du site</h4>
                                <p>
                                    Nom de l'entreprise : E.I. LEONIE DESSERT <br/>
                                    Statut juridique : Entreprise Individuelle (E.I.) <br/>
                                    Adresse : 1 rue de Kernevez, 22740 PLEUMEUR GAUTIER <br/>
                                    Numéro SIRET : 53765949200053 <br/>
                                    Responsable de la publication : Sylvie Lips <br/>
                                    Contact : <a href="mailto:leonie-dessert@gmail.com">leonie-dessert@gmail.com</a> <br/>
                                    Site web : <a href="https://www.leonie-dessert.fr">https://www.leonie-dessert.fr</a> <br/>
                                </p>
                                <h4>2. Hébergeur du site</h4>
                                <p>
                                    Nom de l'hébergeur : HOSTINGER INTERNATIONAL LTD<br/>
                                    Adresse de l'hébergeur : 61 Lordou Vironos Street, 6023 Larnaca, Chypre<br/>
                                    Contact de l'hébergeur : https://www.hostinger.fr/contact
                                </p>
                                <h4>3. Propriété intellectuelle</h4>
                                <p>
                                    L'ensemble des contenus (textes, images, illustrations, logos, etc.) présents sur le site <a href="https://www.leonie-dessert.fr">https://www.leonie-dessert.fr</a> sont la propriété exclusive de E.I. LEONIE DESSERT, sauf mention contraire. Toute reproduction, distribution, modification ou utilisation, même partielle, de ces contenus est interdite sans l'autorisation préalable écrite de l'éditeur.
                                </p>

                                <h4>4. Protection des données personnelles</h4>
                                <p>
                                    Conformément au Règlement Général sur la Protection des Données (RGPD), E.I. LEONIE DESSERT s'engage à protéger les données personnelles des utilisateurs du site. Les données collectées à travers le formulaire de contact ou lors de la navigation (cookies) sont strictement confidentielles et ne sont utilisées que dans le cadre du traitement des demandes.
                                </p>

                                <h4>5. Conformité au RGPD</h4>
                                <p>
                                    E.I. LEONIE DESSERT se conforme aux obligations du RGPD en garantissant la sécurité et la confidentialité des données personnelles. Pour toute question concernant vos données personnelles, vous pouvez nous contacter à l'adresse suivante : <a href="mailto:leonie-dessert@gmail.com">leonie-dessert@gmail.com</a>. Vous disposez d'un droit d'accès, de rectification, de suppression et d'opposition de vos données, que vous pouvez exercer en nous écrivant à cette même adresse.
                                </p>

                                <h4>6. Collecte de données personnelles</h4>
                                <p>
                                    Les données personnelles peuvent être collectées lors de différents moments, tels que lors de la navigation sur le site ou lors du remplissage de formulaires de contact. Ces données incluent notamment le nom, l'adresse électronique, ainsi que les préférences relatives à la navigation.
                                </p>

                                <h4>7. Utilisation des données personnelles</h4>
                                <p>
                                    Les données collectées sont utilisées pour répondre aux demandes des utilisateurs, améliorer l'expérience utilisateur, et pour des raisons administratives et légales. Nous pouvons utiliser ces données pour vous contacter ou vous fournir des informations sur nos services.
                                </p>

                                <h4>8. Cookies et technologies similaires</h4>
                                <p>
                                    Le site utilise des cookies afin d'améliorer l'expérience utilisateur et de collecter des statistiques de fréquentation. Les cookies permettent de personnaliser l'affichage du site et de faciliter la navigation. Vous avez la possibilité de désactiver les cookies en configurant votre navigateur.
                                </p>

                                <h4>9. Durée de conservation des données</h4>
                                <p>
                                    Les données personnelles sont conservées uniquement pour la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées. Cela inclut la gestion des demandes des utilisateurs et la conformité aux obligations légales en vigueur.
                                </p>

                                <h4>10. Vos droits concernant vos données personnelles</h4>
                                <p>
                                    Conformément à la réglementation applicable, vous disposez des droits suivants : droit d'accès, droit de rectification, droit à l'effacement, droit à la limitation du traitement, droit à la portabilité des données et droit d'opposition. Vous pouvez exercer ces droits en nous contactant à <a href="mailto:leonie-dessert@gmail.com">leonie-dessert@gmail.com</a>.
                                </p>

                                <h4>11. Modifications des mentions légales</h4>
                                <p>
                                    E.I. LEONIE DESSERT se réserve le droit de modifier les présentes mentions légales à tout moment. Les modifications prendront effet dès leur publication sur le site. Nous vous invitons donc à consulter régulièrement cette page.
                                </p>

                                <h4>12. Contact</h4>
                                <p>
                                    Pour toute question ou demande d'information concernant le site, vous pouvez nous contacter à l'adresse suivante : <a href="mailto:leonie-dessert@gmail.com">leonie-dessert@gmail.com</a>.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <SplashFooter />
            </main>
        </>
    )
}

export default LegalNotices;