import React from 'react';
import { Link } from 'react-router-dom';


const PropOne = ({projectStyle, data}) => {
    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="thumbnail">
				<Link to={process.env.PUBLIC_URL + `/produit/${data.slug}`}>
					<img src={process.env.PUBLIC_URL + data.image} alt="icon" />
				</Link>
				</div>
				<div className="content">
				<h4 className="title"> 
					<Link to={process.env.PUBLIC_URL + `/produit/${data.slug}`}>{data.name}</Link>
				</h4>
				<span className="subtitle" style={{color: 'black'}}>
					{data.description}
				</span>
				</div>
			</div>
		</>
    )
}

export default PropOne;