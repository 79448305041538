import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProjectPropTwo from './itemProp/ProjectPropTwo';
import useFetch from '../../hook/useFetch';

const ProjectThree = () => {
    const { data: categories } = useFetch(process.env.REACT_APP_API_URL + '/cake-categories');

    return (
        <div className="section section-padding pb--80 pb_lg--40 pb_md--20 pb_sm--0">
        <div className="container">
            <SectionTitle 
            subtitle="Découvrez nos produits aux travers de ces catégories"
            title="Nos Produits"
            description=""
            textAlignment="heading-left"
            textColor=""
            />
            <div className="row row-45">
                {categories.length > 0 && categories.map((data) => (
                    <div className="col-md-6" key={data.id}>
                        <ProjectPropTwo projectStyle="project-style-2" data={data}/>
                    </div>
                ))}
            </div>
        </div>
    </div>
    )
}

export default ProjectThree;